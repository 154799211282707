import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, notification } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './Form.module.css';

function Form() {
    const [questions, setQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [evaluatorEmail, setEvaluatorEmail] = useState('');
    const [evaluateeId, setEvaluateeId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [NomineeName, setNomineeName] = useState('');
    const { id } = useParams();
    const [logo, setLogo] = useState('');
    const navigate = useNavigate();
    const [evaluatorName, setEvaluatorName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        setEvaluateeId(id);
        validateUser();
    }, [id]);

    useEffect(() => {
        document.body.classList.add(styles['form-body']);
        return () => {
            document.body.classList.remove(styles['form-body']);
        };
    }, []);

    const showNotification = (type, message) => {
        notification[type]({
            message: message,
        });
    };

    const validateUser = async () => {
        try {
            const response = await axios.get(`https://form.idacds.systems/evaluatee/${id}`);
            if (!response.data) {
                navigate('/not-found');
            } else {
                setCompanyName(response.data.evaluatee.CompanyName);
                setNomineeName(response.data.evaluatee.FirstName + ' ' + response.data.evaluatee.LastName);
                setLogo(response.data.evaluatee.URL);
                setIsInitialLoading(false);
            }
        } catch (error) {
            console.error('Error validating user:', error);
            navigate('/not-found');
        }
    };

    const fetchQuestions = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`https://form.idacds.systems/questions/${id}`);
            setQuestions(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching questions:', error);
            setIsLoading(false);
            showNotification('error', 'Error fetching questions. Please try again later.');
        }
    };

    const handleNext = () => {
        if (questions[currentIndex].selectedAnswerId !== undefined) {
            setCurrentIndex(prevIndex => Math.min(prevIndex + 1, questions.length - 1));
            setErrorMessage('');
        } else {
            showNotification('error', 'Please select an answer before proceeding to the next question.');
        }
    };

    const handleBack = () => {
        setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const unansweredQuestions = questions.filter(question => question.selectedAnswerId === undefined);
        if (unansweredQuestions.length > 0) {
            showNotification('error', 'Please answer all questions before submitting.');
            return;
        }

        const selectedAnswers = questions.map(question => ({
            questionId: question.id,
            answerId: question.selectedAnswerId
        }));

        axios.post('http://localhost:5000/submit-evaluation', {
            evaluatorEmail,
            evaluateeId,
            responses: selectedAnswers,
            Completed: 1
        });

        setIsSubmitted(true);
    };

    const handleOptionChange = (questionIndex, answerId) => {
        setQuestions(prevQuestions => {
            const updatedQuestions = prevQuestions.map((question, index) => {
                if (index === questionIndex) {
                    return { ...question, selectedAnswerId: answerId };
                }
                return question;
            });
            return updatedQuestions;
        });
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (!evaluatorEmail.trim()) {
            showNotification('error', 'Evaluator Email is required.');
            return;
        }
        setIsEmailSubmitted(true);
        fetchQuestions();
    };

    const progressBarWidth = (currentIndex + 1) / questions.length * 100 + '%';

    if (isInitialLoading) {
        return (
            <div className={styles['loading-screen']}>
                <Spin></Spin>
                <p className={styles['loadingmid']}>Loading...</p>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className={styles['loading-screen']}>
                <Spin></Spin>
                <p className={styles['loadingmid']}>Loading...</p>
            </div>
        );
    }

    if (isSubmitted) {
        return (
            <div className={styles['form-container']}>
                <div className={styles['card']}>
                    <img className={styles['logo']} src={logo} alt="logo" />
                    <h2 id={styles['title']}>Thank You For Your Participation!</h2>
                    <p className={styles['prah2']}>Your cooperation is greatly appreciated. If you have any additional details or feedback, please feel free to share them with us.</p>
                    <br></br>
                    <p className={styles['prah']}>Thank you for your contribution!</p>
                    <br></br>
                    <p className={styles['social']}>Follow Us On:</p>
                    <br></br>
                    <div className={styles['socialsrow']}>
                        <a href="https://web.facebook.com/colomboleadershipacademy/?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer">
                            <img className={styles['socialsicons']} src="https://img.icons8.com/color/48/000000/facebook.png" alt="facebook" />
                        </a>
                        <a href="https://www.linkedin.com/in/colombo-leadership-academy-640123a2/?originalSubdomain=lk" target="_blank" rel="noopener noreferrer">
                            <img className={styles['socialsicons']} src="https://img.icons8.com/color/48/000000/linkedin.png" alt="linkedin" />
                        </a>
                        <a href="https://www.tiktok.com/@clagreatmanagers" target="_blank" rel="noopener noreferrer">
                            <img className={styles['socialsicons']} src="https://img.icons8.com/color/48/000000/tiktok.png" alt="tiktok" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles['form-container']}>
            <div className={styles['card']}>
                <img className={styles['logo']} src={logo} alt="logo" />
                <h2 id={styles['title']}>Let’s Evaluate Your Coworkers!</h2>
                {!isEmailSubmitted ? (
                    <form onSubmit={handleEmailSubmit}>
                        <p id={styles['par']}>
                            Please Enter Your Name, Your Email Address, and 
                            Your Company Name Below.
                        </p>
                        <div className={styles['homeinput']}>
                            <div className={styles['input-container2']}>
                                <h5>Nominee Name</h5>
                                <input
                                    type="text"
                                    value={NomineeName}
                                    disabled
                                />
                            </div>
                            <div className={styles['input-container2']}>
                                <h5>Company Name</h5>
                                <input
                                    type="text"
                                    value={companyName}
                                    disabled
                                />
                            </div>
                            <div className={styles['input-container2']}>
                                <h5>Evaluator Email</h5>
                                <input
                                    type="text"
                                    placeholder="Enter Your Email Address"
                                    value={evaluatorEmail}
                                    onChange={(e) => setEvaluatorEmail(e.target.value)}
                                />
                            </div>
                            <div className={styles['input-container2']}>
                                <h5>Your Name</h5>
                                <input
                                    type="text"
                                    placeholder="Enter Your Name"
                                    value={evaluatorName}
                                    onChange={(e) => setEvaluatorName(e.target.value)}
                                />
                            </div>
                        </div>
                        {errorMessage && <h3 style={{ color: 'red', fontSize: '10px' }}>{errorMessage}</h3>}
                        <button type="submit" className={styles['submit-button-same']}>Start The Survey</button>
                    </form>
                ) : (
                    <>
                        {errorMessage && <h3 style={{ color: 'red', fontSize: '10px' }}>{errorMessage}</h3>}
                        {questions.length > 0 && (
                            <div key={questions[currentIndex].id} className={styles['quizcard']}>
                                <p id={styles['counter']}>Question {currentIndex + 1} ({questions.length - currentIndex - 1} remaining)</p>
                                <p id={styles['qp']}>{questions[currentIndex].text}</p>
                                <div className={styles['radio-container']}>
                                    {questions[currentIndex].answers.map(answer => (
                                        <label key={answer.id}>
                                            <input
                                                type="radio"
                                                name={`question_${questions[currentIndex].id}`}
                                                checked={questions[currentIndex].selectedAnswerId === answer.id}
                                                onChange={() => handleOptionChange(currentIndex, answer.id)}
                                            />
                                            {answer.text}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className={styles['progress-bar']} style={{ width: progressBarWidth }}></div>
                        <div className={styles['progress-text']}>{currentIndex + 1} / {questions.length}</div>
                        <button id={styles['back']} onClick={handleBack} disabled={currentIndex === 0}>Back</button>
                        {currentIndex < questions.length - 1 && (
                            <button className={styles['next']} onClick={handleNext}>Continue</button>
                        )}
                        {currentIndex === questions.length - 1 && (
                            <button className={styles['submit-button-same2']} onClick={handleSubmit}>Submit Evaluation</button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Form;
